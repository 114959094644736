import useJwt from '@/auth/jwt/useJwt'
import { API_ROUTES } from '../config/routes/api'

export default {
	async getAll(brandId) {
		const params = {
			order: 'desc',
			order_by: 'name',
			offset: 0,
			limit: 0,
		}
		const url = API_ROUTES.device.get.replace(':id', brandId)

		const { data } = await useJwt.get(url, params)

		return data
	},
	async find(id) {
		const { data } = await useJwt.get(`${API_ROUTES.device.find}/${id}`)

		return data
	},
	async save(id, data) {
		return useJwt.put(`${API_ROUTES.device.save}/${id}`, data)
	},
	delete(id) {
		return useJwt.delete(`${API_ROUTES.device.delete}/${id}`)
	},
	update(id, data) {
		return useJwt.patch(`${API_ROUTES.device.update}/${id}`, data)
	},
	async getDeviceTypeAll() {
		const { data } = await useJwt.get(`${API_ROUTES.device_type.get}`)

		return data
	},
}
